html{
  background-color: #0e0e10;
}

body{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height:100vh;
  background-color: #1f1f23;
}

.card{
  width: 30vw;
  background-color: #0e0e10;
  border: 1px #1c1c1d solid;
  color: lightgray;
}

.card-header{
  border-bottom: 1px #1c1c1d solid;
}

.card a{
  color: white;
}

.card a:hover{
  color: #a970ff;
  text-decoration: none;
}

iframe {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.card img, .card button{
  height:100%;
  width: 100%;
  left: 0;
  top: 0;
}

.card-body{
  height:100%;
  font-Size: 1vw;
}

.card-group{
  height: 24vw;
}

#leftButton, #rightButton{
  background-color: #a970ff;
  color: white;
  height: 30vh;
  width: 3vw;
  margin: auto;
}

#timeSelect{
  display:flex ;
  justify-content:center;
  margin: auto;
}

#pages{
  display:flex;
  justify-content:center;
  margin: auto;
}

.page-link{
  background-color: #0e0e10;
  color: white;
}

.page-link:hover{
  background-color: #a970ff ;
  text-decoration: none ;
}

#activePage .page-link{
  background-color: #a970ff ;
}

#activeTime {
  background-color: #a970ff ;
}